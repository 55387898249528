@tailwind base;
@tailwind components;

@tailwind utilities;

body,
html,
#root {
  @apply h-full;
}

@layer base {
  :root {
    --color-input-label: rgb(55 65 81);
    --color-input-error: rgb(127 29 29);
  }

  .theme-dark {
    --color-input-label: white;
    --color-input-error: rgb(254 202 202);
  }
}

@layer components {
  /* LINK */

  .text-link {
    @apply text-purple-500 hover:text-purple-300 transition-colors duration-150;
  }

  .text-link-black {
    @apply text-brand-500 hover:text-brand-300 transition-colors duration-150;
  }

  .text-link-danger {
    @apply text-red-700 hover:text-red-500 transition-colors duration-150;
  }

  /* BUTTON */

  .btn {
    @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 relative transition-colors duration-150;
  }
  .btn-primary {
    @apply text-white bg-brand-500 hover:bg-brand-700 focus:ring-brand-500 disabled:bg-gray-50 disabled:text-gray-700 disabled:border-gray-400;
  }
  .btn-dark-purple {
    @apply text-white bg-purple-500 hover:bg-purple-700 focus:ring-brand-500 disabled:bg-gray-50 disabled:text-gray-700 disabled:border-gray-400;
  }
  .btn-light-purple {
    @apply text-purple-700 bg-purple-50 hover:bg-purple-100 focus:ring-purple-500 disabled:bg-gray-50 disabled:text-gray-700 disabled:border-gray-400;
  }
  .btn-light-purple {
    @apply text-purple-700 bg-purple-50 hover:bg-purple-100 focus:ring-purple-500 disabled:bg-gray-50 disabled:text-gray-700 disabled:border-gray-400;
  }
  .btn-secondary {
    @apply text-brand-700 bg-brand-50 hover:bg-brand-200 focus:ring-brand-500 disabled:bg-gray-50;
  }
  .btn-secondary-delete {
    @apply text-red-800 hover:bg-red-100 border-red-500 hover:border-red-800 focus:ring-red-500 disabled:bg-gray-50;
  }
  .btn-white {
    @apply border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-brand-500;
  }
  .btn-full {
    @apply w-full flex justify-center;
  }
  .btn-delete {
    @apply text-white bg-red-500 hover:bg-red-800 focus:ring-red-800;
  }
  .leading-icon {
    @apply -ml-0.5 mr-2 h-4 w-4;
  }
  .trailing-icon {
    @apply ml-3 -mr-1 h-5 w-5;
  }

  .btn-filter::after {
    @apply absolute -top-2 -right-2 rounded-full bg-brand-500 text-white w-5 h-5;
  }

  /* INPUT */

  .input {
    @apply appearance-none flex w-full border border-gray-300 rounded-md shadow-sm focus:outline-none;
  }

  .input-error {
    @apply border-red-300 focus:ring-red-500 focus:border-red-500;
  }

  .select {
    @apply form-select bg-white input sm:text-sm px-3 py-2 mt-1 disabled:bg-gray-50 disabled:opacity-100;
  }

  .css-1s2u09g-control {
    @apply border-red-400;
  }

  .number-as-text {
    -moz-appearance: textfield;
  }

  .number-as-text::-webkit-outer-spin-button,
  .number-as-text::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* TABLE */

  .table-header {
    @apply px-4 py-3 text-left text-xs leading-4 font-medium text-gray-800 bg-gray-100 uppercase tracking-wider;
  }

  /* TEXT */

  .page-tile {
    @apply text-3xl font-semibold text-gray-800;
  }

  .light-text {
    @apply font-normal text-sm text-gray-500;
  }

  .section-title {
    @apply text-3xl mb-4 font-normal text-gray-800;
  }

  .landing-title-section {
    @apply text-3xl font-semibold text-gray-900;
  }

  .landing-light-text {
    @apply font-normal text-lg text-gray-500;
  }

  .landing-sub-title {
    @apply font-semibold text-purple-700;
  }

  .qualification-tag {
    @apply py-[2px] px-3 text-purple-700 bg-purple-100 rounded-full font-medium text-sm;
  }

  /* CONTAINERS */

  .card {
    @apply bg-white shadow-md rounded-lg px-4 py-5 sm:py-6;
  }

  /* FORMIK MARK CHECKBOX */

  .mark-icon {
    @apply w-14 h-14 rounded-full flex justify-center items-center mb-4 bg-gray-100 text-gray-500 transition-colors duration-200;
  }

  /* TAGS */

  .tag {
    @apply py-1 px-3 rounded-full text-xs leading-4 font-medium;
  }

  .positive-tag {
    @apply text-green-800 bg-green-100;
  }

  .negative-tag {
    @apply text-red-800 bg-red-100;
  }

  .alert-tag {
    @apply text-yellow-800 bg-yellow-100;
  }
}
